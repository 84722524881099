$ = window.$ = window.jQuery;
require("./../../bower_components/owl.carousel/dist/owl.carousel.js");

$(document).ready(function () {


    $('#buildpress-navbar-collapse > ul').addClass('primary-menu navigation--main').attr('id', 'menu-main-menu');
    // console.log('yata');

    (function () {

        if (document.querySelector('#partnerzy-w-biznesie')) {
            console.log('asd');
            $('.owl-carousel').owlCarousel({
                loop:true,
                margin:10,
                responsiveClass:true,
                dots:false,
                autoplay:true,
                autoplayTimeout:3000,
                responsive:{
                    0:{
                        items:1,
                        slideBy:1,
                    },
                    600:{
                        items:3,
                        slideBy:3,
                    },
                    1000:{
                        items:5,
                        slideBy:5,
                    }
                }
            })
        }


        console.log('asdasdasdasdasdasdasdasd');

        // Cache selectors
        var lastId,
            topMenu = $("#menu-main-menu"),
            //footerMenu = $('#footer-nav'),
            topMenuHeight = topMenu.outerHeight() + 15,
        // All list items
            menuItems = topMenu.find("li.slide-me-down a"),
            //menuFooterItems = footerMenu.find("a"),
        // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
                var item = $($(this).attr("href"));
                if (item.length) {
                    return item;
                }
            });

        // Bind click handler to menu items
        // so we can get a fancy scroll animation
        menuItems.click(function (e) {
            var href = $(this).attr("href"),
                offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;

            console.log(href);
            $('html, body').stop().animate({
                scrollTop: offsetTop
            }, 300);

            e.preventDefault();
        });

        //menuFooterItems.click(function (e) {
        //    var href = $(this).attr("href"),
        //        offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
        //    $('html, body').stop().animate({
        //        scrollTop: offsetTop
        //    }, 300);
        //    e.preventDefault();
        //});

    // Bind to scroll
        $(window).scroll(function () {
            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight;

            // Get id of current scroll item
            var cur = scrollItems.map(function () {
                if ($(this).offset().top < fromTop)
                    return this;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems
                    .parent().removeClass("active")
                    .end().filter("[href='#" + id + "']").parent().addClass("active");
            }
        });
    })();




//    $('.no-touch .animated').waypoint(function () {
//
//        var animation = $(this).attr('data-animation');
//        var xposition = $(this).attr('data-xposition');
//        var yposition = $(this).attr('data-yposition');
//        var delay = $(this).attr('data-animation-delay');
//
//        $(this).addClass(animation, function () {
//            $(this).css({
//                opacity: '1',
//                marginLeft: xposition + 'px',
//                marginTop: '-' + yposition + 'px',
//                animationDelay: delay + 'ms'
//            });
//        });
//
//    }, {
//        offset: '85%',
//        triggerOnce: true
//    });
//
//    //INITIALIZES FLEXSLIDER CAROUSELS
//
//    $('.carousel-container').flexslider({
//        animation: 'slide',
//        slideshow: true,
//        animationLoop: false,
//        controlNav: false,
//        prevText: '<i class="fa fa-angle-left"></i>',
//        nextText: '<i class="fa fa-angle-right"></i>',
//        itemWidth: 234,
//        itemMargin: 24,
//        touch: true
//    });
//
//    $('.carousel-full').parent('.col-row').css({marginRight: '0'});
//
//    $('.image-gallery').flexslider({
//        animation: 'fade',
//        animationLoop: false,
//        controlNav: false,
//        prevText: '<i class="fa fa-angle-left"></i>',
//        nextText: '<i class="fa fa-angle-right"></i>',
//    });
//
//    //INITIALIZES NIVO LIGHTBOX PLUGIN
//
//    $('a[data-nivo-rel^="nivoLightbox"]').nivoLightbox({
//        effect: 'fade'
//    });
//
//    //ACCORDIONS AND TABS
//
//    $('.accordion').accordion({
//        collapsible: true,
//        heightStyle: 'content'
//    });
//
//    $('.tabs-top, .tabs-top-2').tabs({
//        show: {
//            effect: 'fadeIn',
//            duration: 500
//        }
//    });
//
//    $('.tabs-side, .tabs-side-2').tabs({
//        show: {
//            effect: 'fadeIn',
//            duration: 500
//        }
//    }).addClass('ui-tabs-vertical ui-helper-clearfix');
//
//    $('.tabs-side li').removeClass('ui-corner-top').addClass('ui-corner-left');
//
//
//    (function () {
//
//        // Cache selectors
//        var lastId,
//            topMenu = $("#menu-main-menu"),
//            footerMenu = $('#footer-nav'),
//            topMenuHeight = topMenu.outerHeight() + 15,
//        // All list items
//            menuItems = topMenu.find("a"),
//            menuFooterItems = footerMenu.find("a"),
//        // Anchors corresponding to menu items
//            scrollItems = menuItems.map(function () {
//                var item = $($(this).attr("href"));
//                if (item.length) {
//                    return item;
//                }
//            });
//
//// Bind click handler to menu items
//// so we can get a fancy scroll animation
//        menuItems.click(function (e) {
//            var href = $(this).attr("href"),
//                offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
//            $('html, body').stop().animate({
//                scrollTop: offsetTop
//            }, 300);
//            e.preventDefault();
//        });
//
//        menuFooterItems.click(function (e) {
//            var href = $(this).attr("href"),
//                offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
//            $('html, body').stop().animate({
//                scrollTop: offsetTop
//            }, 300);
//            e.preventDefault();
//        });
//
//// Bind to scroll
//        $(window).scroll(function () {
//            // Get container scroll position
//            var fromTop = $(this).scrollTop() + topMenuHeight;
//
//            // Get id of current scroll item
//            var cur = scrollItems.map(function () {
//                if ($(this).offset().top < fromTop)
//                    return this;
//            });
//            // Get the id of the current element
//            cur = cur[cur.length - 1];
//            var id = cur && cur.length ? cur[0].id : "";
//
//            if (lastId !== id) {
//                lastId = id;
//                // Set/remove active class
//                menuItems
//                    .parent().removeClass("active")
//                    .end().filter("[href='#" + id + "']").parent().addClass("active");
//            }
//        });
//    })();
//
//
//    //SCROLL TO TOP TRIGGER
//
//    $(window).scroll(function () {
//        if ($(this).scrollTop() > 100) {
//            $('.scroll-top').fadeIn('slow').removeClass('scroll-top-hidden').addClass('scroll-top-visible');
//        } else {
//            $('.scroll-top').fadeOut('slow').removeClass('scroll-top-visible').addClass('scroll-top-hidden');
//        }
//    });
//
//    $('.scroll-top').click(function () {
//        $('body,html').animate({
//            scrollTop: 0
//        }, 800);
//        return false;
//    });
//
//    $('.content-slider').parent('.container-slider').hover(function () {
//            $(this).find('.flex-direction-nav').animate({
//                opacity: '1'
//            }, 200);
//        },
//        function () {
//            $(this).find('.flex-direction-nav').animate({
//                opacity: '0'
//            }, 200);
//        });
//
//    //CREATING RESPONSIVE NAVIGATION (DROPDOWN MENU)
//
//    $('<div class="responsive-nav" />').appendTo('#header');
//
//    var $navigation = $('<select />');
//    $('<option />', {
//        'selected': 'selected',
//        'value': '',
//        'text': 'Menu'
//    }).appendTo($navigation);
//
//    $navigation.appendTo('.responsive-nav');
//
//    $('#main-navigation ul li a').each(function () {
//
//        var navUrl = $(this).attr('href');
//        var navText = $(this).clone().children().remove().end().text();
//
//        if ($(this).parents('li').length == 2) {
//            navText = '- ' + navText;
//        }
//        if ($(this).parents('li').length == 3) {
//            navText = '-- ' + navText;
//        }
//        if ($(this).parents('li').length > 3) {
//            navText = '--- ' + navText;
//        }
//
//        $('<option />', {
//            'value': navUrl,
//            'text': navText
//        }).appendTo($navigation)
//    });
//
//    field_id = '.responsive-nav select';
//
//    $(field_id).change(function () {
//        value = $(this).attr('value');
//        window.location = value;
//    });

});

//
////INITIALIZES THE PERSISTENT TOP NAVIGATION BAR ON SMALLER SCREENS
//
//$('.responsive-nav').waypoint('sticky', {
//    stuckClass: 'stuck',
//    offset: -150
//});
//
//
////PRELOADER
//
//
//$(window).on('load', function () {
//    $('#status').fadeOut('fast'); // will first fade out the loading animation
//    $('#preloader').delay(250).fadeOut('slow', function () {
//        $(this).addClass('none');
//    }); // will fade out the white DIV that covers the website.
//    $('body').delay(1000).css({'overflow': 'visible', 'height': 'auto'});
//});